'use client';

import React, { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { LockOutlined, UserOutlined, LinkedinFilled, InstagramFilled, MailFilled, CloseOutlined } from '@ant-design/icons';
import { ConfigProvider, Col, Row, Input, Form, Button, Select, Modal, Divider, notification } from 'antd';
import bg from '@/public/img/background-login.png';
import logo from '@/public/img/logo.png';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useAuth } from '@/contexts/authContext';
import '@/app/globals.css';
import theme from '@/theme/themeConfig';

const LogoComponent: React.FC = () => {
    const [logoWidth, setLogoWidth] = useState(400);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setLogoWidth(width < 768 ? 200 : 400);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <Image src={logo} alt="logo" width={logoWidth} style={{ height: 'auto' }} />;
};

const Login: React.FC = () => {
    const { grupoContext, login, logout, isAutenticated, redefinirSenha, perfil, handleGrupo, handleUnidade } = useAuth();
    const [token, setToken] = useState('');
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSelectUnidade, setLoadingSelectUnidade] = useState(false);


    useEffect(() => {
        if (isAutenticated) {
            // Se necessário, ações adicionais podem ser realizadas aqui
        }
    }, [isAutenticated]);

    const onFinish = async (values: any) => {
        setLoading(true);
        try {
            await login(values.username, values.password, token);
        } catch (error) {
            notification.error({
                message: 'Erro de Login',
                description: 'Ocorreu um erro ao tentar fazer login. Por favor, tente novamente.',
            });
        } finally {
            setLoading(false);
            setRefreshReCaptcha(true);
        }
    };

    const onVerify = useCallback((token: string) => {
        setToken(token);
    }, []);

    useEffect(() => {
        setRefreshReCaptcha(false);
    }, [refreshReCaptcha]);

    const handleSelectUnidade = async (unidadeId: number) => {
        setLoadingSelectUnidade(true);
        try {
            await handleUnidade(unidadeId);
        } catch (error) {
            notification.error({
                message: 'Erro na Seleção de Unidade',
                description: 'Não foi possível selecionar a unidade. Por favor, tente novamente.',
            });
        } finally {
            setLoadingSelectUnidade(false);
        }
    };

    return (
        <ConfigProvider theme={theme}>
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                    overflow: "auto",
                    backgroundImage: `url(${bg.src})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    columnGap: 20,
                }}
            >
                <Col>
                    <Col
                        className='logo-col'
                        style={{
                            textAlign: 'center',
                            marginBottom: '90px',
                            padding: '10px',
                        }}
                    >
                        <LogoComponent />
                    </Col>
                </Col>

                <Col>
                    <Col style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Col
                            style={{
                                width: 350,
                                height: 350,
                                flexShrink: 0,
                                borderRadius: 30,
                                opacity: 0.9,
                                background: 'rgba(255, 255, 255, 0.6)',
                                boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                                justifyContent: 'center',
                            }}
                        >
                            {!isAutenticated || redefinirSenha ? (
                                <Form
                                    name="login"
                                    className="login-form"
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                    autoComplete="off"
                                    style={{ width: '90%', textAlign: 'center' }}
                                >
                                    <Col
                                        style={{
                                            width: '100%',
                                            marginBottom: 1,
                                            color: '#045184',
                                            fontSize: 20,
                                            fontWeight: '700',
                                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                                        }}
                                    >
                                        Acesso ao Sistema
                                    </Col>
                                    <Divider style={{ borderBlockStartColor: 'rgba(5, 5, 5, 0.1)' }} />

                                    <Form.Item
                                        name="username"
                                        validateTrigger="onBlur"
                                        rules={[{ required: true, type: 'email', message: 'Insira um e-mail válido.' }]}
                                    >
                                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Digite seu e-mail" />
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: 'Por favor, insira sua senha.' }]}
                                    >
                                        <Col>
                                            <Input.Password
                                                prefix={<LockOutlined className="site-form-item-icon" />}
                                                placeholder="Senha"
                                            />
                                            <RecuperarSenhaModal />
                                        </Col>
                                    </Form.Item>

                                    <Form.Item name="submit">
                                        <Col>
                                            <Button block type="primary" htmlType="submit" loading={loading} style={{ background: '#3276b1' }}>
                                                Entrar
                                            </Button>
                                            <p style={{ fontSize: 12, padding: 1, color: '#514747', textAlign: 'right' }}>&copy; 2023 - Patrymon</p>
                                        </Col>
                                    </Form.Item>
                                </Form>
                            ) : (
                                <Form
                                    name="grupo"
                                    className="login-form"
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                    autoComplete="off"
                                    style={{ width: '90%', textAlign: 'center' }}
                                >
                                    <Row gutter={16} justify="end">
                                        <Col>
                                            <Button
                                                type="text"
                                                onClick={logout}
                                                style={{ opacity: 0.5, marginTop: '-8px' }} 
                                                icon={<CloseOutlined style={{ fontSize: '12px' }} />}
                                            />
                                        </Col>
                                    </Row>

                                    <Col
                                        style={{
                                            width: '100%',
                                            marginBottom: 1,
                                            color: '#045184',
                                            fontSize: 20,
                                            fontWeight: '700',
                                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                                        }}
                                    >
                                        Bem Vindo à Patrymon 2.2
                                    </Col>
                                    <Divider style={{ borderBlockStartColor: 'rgba(5, 5, 5, 0.1)' }} />

                                    <Form.Item
                                        name="grupo"
                                        rules={[{ required: true, message: 'Selecione um grupo.' }]}
                                    >
                                        <Select
                                            style={{ textAlign: 'left', textTransform: 'capitalize' }}
                                            showSearch
                                            optionFilterProp="label"
                                            value={grupoContext.grupo}
                                            placeholder="Empresa"
                                            onChange={handleGrupo}
                                            options={perfil?.empresas?.map((empresa) => ({ label: empresa.nome, value: empresa.id }))}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="unidade"
                                        rules={[{ required: true, message: 'Selecione uma unidade.' }]}
                                    >
                                        <Select
                                            placeholder="Unidade de Negócio"
                                            showSearch
                                            optionFilterProp="label"
                                            style={{ textAlign: 'left', textTransform: 'capitalize' }}
                                            value={grupoContext.unidade}
                                            onChange={handleUnidade}
                                            options={perfil?.empresas?.find(x => x.id === grupoContext.grupo)?.unidades?.map((unidade) => ({ label: unidade.nome, value: unidade.id }))}
                                        />
                                    </Form.Item>

                                    <Form.Item name="submit">
                                        <Row gutter={16}>
                                            <Col xs={24} sm={24} md={24} lg={24}>
                                                <Button
                                                    block
                                                    type="primary"
                                                    onClick={() => handleSelectUnidade(grupoContext.unidade ?? 0)}
                                                    loading={loadingSelectUnidade}
                                                    style={{ background: '#3276b1' }}
                                                    disabled={loadingSelectUnidade}
                                                >
                                                    Selecionar
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Col style={{ fontSize: 12, padding: 1, color: '#514747', textAlign: 'right' }}>&copy; 2023 - Patrymon</Col>
                                    </Form.Item>
                                </Form>
                            )}

                            <GoogleReCaptchaProvider reCaptchaKey="6LcVlJYbAAAAAJrKLOkgvFX30lot9a-SBRKUoM_1">
                                <GoogleReCaptcha
                                    refreshReCaptcha={refreshReCaptcha}
                                    onVerify={onVerify}
                                />
                            </GoogleReCaptchaProvider>
                        </Col>
                    </Col>

                    <Col
                        style={{
                            width: '100%',
                            height: 60,
                            flexShrink: 0,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 10,
                            justifyContent: 'center',
                        }}
                    >
                        <Col
                            className='socialIcons'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                marginTop: 20,
                            }}
                        >
                            <a href="https://www.linkedin.com/company/patrymon-services/about/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', transition: 'color 0.3s' }}>
                                <LinkedinFilled className='icon' style={{ fontSize: '30px', color: 'rgba(169, 169, 169, 0.8)', transition: 'color 0.3s' }} />
                            </a>
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', transition: 'color 0.3s' }}>
                                <InstagramFilled className='icon' style={{ fontSize: '30px', color: 'rgba(169, 169, 169, 0.8)', transition: 'color 0.3s' }} />
                            </a>
                            <a href="mailto:comercial@patrymon.com.br" style={{ textDecoration: 'none', color: 'inherit', transition: 'color 0.3s' }}>
                                <MailFilled className='icon' style={{ fontSize: '30px', color: 'rgba(169, 169, 169, 0.8)', transition: 'color 0.3s' }} />
                            </a>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </ConfigProvider>
    );
};

const RecuperarSenhaModal: React.FC = () => {
    const { recuperarSenha } = useAuth();
    const [recuperarSenhaModal, setRecuperarSenhaModal] = useState(false);
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);

    const abrirModal = () => {
        setRecuperarSenhaModal(true);
    };

    const fecharModal = () => {
        setRecuperarSenhaModal(false);
        form.resetFields();
    };

    const onFinish = async (values: any) => {
        setConfirmLoading(true);
        try {
            await recuperarSenha(values.username);
            notification.success({
                message: 'Recuperação de Senha',
                description: 'Um e-mail com instruções de recuperação foi enviado para o seu endereço.',
            });
        } catch (error) {
            notification.error({
                message: 'Erro na Recuperação de Senha',
                description: 'Ocorreu um erro ao tentar recuperar a senha. Por favor, tente novamente.',
            });
        } finally {
            setConfirmLoading(false);
            fecharModal();
        }
    };

    return (
        <>
            <Col style={{ width: '100%', textAlign: 'right' }}>
                <a style={{ fontSize: 12 }} className='login-form-forgot' href='#' onClick={abrirModal}>
                    Esqueci a senha
                </a>
            </Col>

            <Modal
                title='Recuperar Senha'
                open={recuperarSenhaModal}
                onOk={() => form.submit()}
                confirmLoading={confirmLoading}
                okText='Recuperar Senha'
                onCancel={fecharModal}
                cancelText='Cancelar'
            >
                <Form
                    form={form}
                    name='recuperarSenha'
                    className='login-form'
                    onFinish={onFinish}
                    style={{ width: '90%', textAlign: 'center' }}
                >
                    <Form.Item
                        name='username'
                        rules={[{ required: true, type: 'email', message: 'Informe o seu e-mail para recuperação de senha.' }]}
                    >
                        <Input placeholder='Informe o seu e-mail para recuperação de senha.' />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default Login;
